<template>
  <div>
    <CRow>
      <CCol>
  <CCard>
   
  <CCardBody>

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
        :responsive="true">
       <template #approvedStatus-filter>
          <select
            class="form-control form-control-sm"
            @input="
              $refs.vuetable.columnFilterEvent(
                'approvedStatus',
                $event.target.value,
                'input'
              )
            "
          >
            <option value selected="selected">Any</option>
            <option value="1">Not Approved</option>
            <option value="2">Approved</option>
            <option value="3">Rejected</option>
          </select>
        </template>

      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

     
     <template #skillSetName="{item}">
          <td class='font-weight-bold'
          v-if="item.firstName && item.lastName"
        >{{ item.firstName }} {{item.lastName }}</td>
        <td v-else>--</td>
      </template>
      <template #approvedStatus="{ item }">
          <td class='font-weight-bold' v-show="item.approvedStatus == 1">Not Approved</td>
          <td class='font-weight-bold' v-show="item.approvedStatus == 2">Approved</td>
        <td class='font-weight-bold' v-show="item.approvedStatus == 3">Rejected</td>
        </template>
     
      </CDataTable>
       <CCardFooter align="right">

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
  </CCardBody>
  <div ref="modalArea"></div>

 </CCard>
 
      </CCol>
      </CRow>
 </div>
</template>

<script>
const fields = [
  'index',
 
  { key: "skillSetName", label: "Officer Name", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "approvedStatus", label: "Approved Status", _classes: 'font-weight-bold', _style: "min-width:200px" },
//   { key: "createdTime",label: "Created Date & Time",_classes: 'font-weight-bold', _style: "min-width:200px" },
//    { key: "show_details",
//     label: "",
//     _style: "width:1%",
//     sorter: false,
//     filter: false,
//   },
  
];

export default {
  name: "PermitOfficers",
 
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getApprovalSkillSet() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/work/permit/Officers/"+this.$route.params.requestId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
   cancel() {
   
           window.history.back();
    },
   
  },
  mounted() {
    this.getApprovalSkillSet();
  },
};
</script>